import { Signal } from "./signal.js";
export class Queue {
    buffer = [];
    signal = new Signal();
    async *run() {
        while (true) {
            while (this.buffer.length) {
                const [cb] = this.buffer.splice(0, 1);
                yield* cb();
            }
            yield* this.signal.wait();
        }
    }
    push(cb) {
        this.buffer.push(cb);
        this.signal.notify();
    }
}
