import { default as libAxios } from 'axios';
import { context } from './cancellable.js';
export async function* axios(cfg) {
    const ctx = yield* context();
    const controller = new AbortController();
    ctx.onCancel(controller.abort.bind(controller));
    try {
        return await libAxios({
            ...cfg,
            signal: controller.signal
        });
    }
    finally {
        ctx.done();
    }
}
