import { CancelablePromise, fetch, run } from "@repaya/commons/async2"
import { BrowserClient as RPCClient } from "@repaya/commons/rpc/browser"
import { useConfigStore } from "./config"
import { createGlobalCache } from "../common/hooks"
import { isUnauthorizedError } from "../auth/error"
import { useAuthStore } from "../auth/state"

const getRPCCLientCache = createGlobalCache(url => new RPCClient(url, true))

function getRPCClient() {
    return getRPCCLientCache(useConfigStore.getState().baseUrl)
}

export async function* request<T>(method: string, params: any) {
    const client = getRPCClient()
    try {
        return yield* client.request<T>(method, params)
    } catch (error) {
        if (isUnauthorizedError(error)) {
            useAuthStore.getState().setUser(null)
            throw error
        }
        console.error(error)
        throw error
    }
}