import { useEffect, useRef, useState, useSyncExternalStore } from "react"
import create, { StoreApi, UseBoundStore } from "zustand"
import { createGlobalEffect } from "./hooks"
import { Storage } from './storage'

type StoreEffect<S> = () => ((() => void) | void)

export function withStoreEffect<S>
    (hook: UseBoundStore<StoreApi<S>>, effect: StoreEffect<S>): UseBoundStore<StoreApi<S>> {
    const useGlobalEffect = createGlobalEffect()

    const r = ((...args: Parameters<typeof hook>) => {
        useGlobalEffect(effect)
        return hook(...args)
    }) as UseBoundStore<StoreApi<S>>

    Object.assign(r, hook)

    return r
}

function loadStorageData<S>(storage: Storage, key: string, fallback: S): S {
    try {
        const data = storage.getItem(key)
        if (!data)
            return fallback

        return JSON.parse(data)
    } catch (ignore) {
        return fallback
    }
}

function saveStorageData<S>(storage: Storage, key: string, data: S) {
    try {
        storage.setItem(key, JSON.stringify(data))
    } catch (ignore) {

    }
}

export function withPersistentStore<S extends Object>
    (storage: Storage, key: string, hook: UseBoundStore<StoreApi<S>>): UseBoundStore<StoreApi<S>> {
    return withStoreEffect(hook, () => {
        hook.setState(loadStorageData(storage, key, hook.getState()))
        return hook.subscribe(state => {
            saveStorageData(storage, key, state)
        })
    })
}

export function useLocalStore<T>(create: () => UseBoundStore<StoreApi<T>>): [T, StoreApi<T>] {
    const storeRef = useRef(create())

    const state = useSyncExternalStore(
        storeRef.current.subscribe.bind(storeRef.current),
        storeRef.current.getState.bind(storeRef.current))

    return [state, storeRef.current]
}