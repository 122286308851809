import { unauthorizedErrorCode } from "./constants";

export function isUnauthorizedError(error: any): boolean {
    return (typeof error === 'object' && error && 'code' in error
        && (error as { code: number }).code == unauthorizedErrorCode)
}

export function isRejectedSigningError(error: any): boolean {
    return (typeof error === 'object' && error && 'action' in error
        && (error as { action: string }).action == 'signMessage') && (
            typeof error === 'object' && error && 'code' in error
            && (error as { code: string }).code == 'ACTION_REJECTED'
        )
}