import { all, run, wait } from "./cancellable.js";
export class CancelableGroup {
    id = 0;
    promises = new Map();
    waitPromise = null;
    async *wait() {
        if (this.waitPromise === null) {
            const self = this;
            this.waitPromise = run(async function* () {
                let buff = Array.from(self.promises.values());
                while (buff.length > 0) {
                    yield* all(buff.map(b => wait(b)));
                    buff = Array.from(self.promises.values());
                }
            }());
        }
        yield* wait(this.waitPromise);
    }
    run(gen) {
        const id = ++this.id;
        const self = this;
        this.promises.set(id, run(async function* () {
            try {
                return yield* gen;
            }
            finally {
                self.promises.delete(id);
            }
        }()));
    }
    cancel = () => {
        const buff = Array.from(this.promises.values());
        this.promises.clear();
        buff.forEach(p => p.cancel());
        if (this.waitPromise != null)
            this.waitPromise.cancel();
    };
}
