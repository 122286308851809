const noop = () => { };
export function run(gen) {
    let isCancelled = false;
    let isDone = false;
    let callbacks = [];
    const contextYield = {
        context: () => {
            let localCallbacks = [];
            const localHandler = () => {
                if (isCancelled && !isDone) {
                    for (const cb of localCallbacks)
                        cb();
                }
                ctx.done();
            };
            callbacks.push(localHandler);
            const ctx = {
                isCanceled: () => isCancelled,
                onCancel: cb => localCallbacks.push(cb),
                done: () => {
                    localCallbacks = [];
                    const ndx = callbacks.findIndex(a => a === localHandler);
                    if (ndx !== -1)
                        callbacks.splice(ndx, 1);
                }
            };
            return ctx;
        }
    };
    const promise = new Promise((resolve, reject) => {
        async function run() {
            try {
                let result;
                while (!result || !result.done) {
                    if (isCancelled) {
                        callbacks = [];
                        return;
                    }
                    result = await gen.next(contextYield);
                }
                isDone = true;
                if (!isCancelled) {
                    isCancelled = true;
                    callbacks = [];
                    resolve(result.value);
                }
            }
            catch (err) {
                isDone = true;
                if (!isCancelled) {
                    isCancelled = true;
                    callbacks = [];
                    reject(err);
                }
            }
        }
        run();
    });
    promise.cancel = () => {
        if (isCancelled)
            return;
        isCancelled = true;
        const buff = callbacks.slice().reverse();
        for (const cb of buff)
            cb();
        callbacks = [];
    };
    promise.lib = 'async2';
    return promise;
}
export async function* context() {
    return (yield).context();
}
export async function* background(gen) {
    const ctx = yield* context();
    const promise = run(async function* () {
        try {
            yield* gen;
        }
        finally {
            ctx.done();
        }
    }());
    ctx.onCancel(promise.cancel);
}
export async function* wait(promise) {
    const ctx = yield* context();
    ctx.onCancel(() => {
        if (promise.cancel)
            promise.cancel();
    });
    try {
        return await promise;
    }
    finally {
        ctx.done();
    }
}
export async function* all(gens) {
    const ctx = yield* context();
    const promises = gens.map(gen => run(gen));
    ctx.onCancel(() => promises.forEach(p => p.cancel()));
    try {
        return await Promise.all(promises);
    }
    finally {
        promises.forEach(p => p.cancel());
        ctx.done();
    }
}
export async function* any(gens) {
    const ctx = yield* context();
    const promises = gens.map(gen => run(gen));
    ctx.onCancel(() => promises.forEach(p => p.cancel()));
    try {
        return await Promise.any(promises);
    }
    finally {
        promises.forEach(p => p.cancel());
        ctx.done();
    }
}
export async function* delay(delay) {
    const ctx = yield* context();
    let timer;
    ctx.onCancel(() => clearTimeout(timer));
    try {
        await new Promise(resolve => {
            timer = setTimeout(resolve, delay);
        });
    }
    finally {
        ctx.done();
    }
}
export async function* requestAnimationFrame() {
    if (typeof window === 'undefined')
        return;
    const ctx = yield* context();
    let request;
    ctx.onCancel(() => window.cancelAnimationFrame(request));
    try {
        await new Promise(resolve => {
            request = window.requestAnimationFrame(resolve);
        });
    }
    finally {
        ctx.done();
    }
}
export class ErrorTimeout extends Error {
    constructor(msg) {
        super(msg ?? 'timeout');
        Object.setPrototypeOf(this, ErrorTimeout.prototype);
    }
}
export async function* timeout(timeout, gen) {
    async function* error() {
        yield* delay(timeout);
        return new ErrorTimeout();
    }
    const res = yield* any([gen, error()]);
    if (res instanceof ErrorTimeout)
        throw res;
    return res;
}
export async function* fetch(url, init) {
    const ctx = yield* context();
    const controller = new AbortController();
    ctx.onCancel(controller.abort.bind(controller));
    try {
        return await window.fetch(url, {
            ...(init ?? {}),
            signal: controller.signal
        });
    }
    finally {
        ctx.done();
    }
}
export function noRace(fn) {
    let prev = null;
    return (...args) => {
        if (prev != null && prev.cancel)
            prev.cancel();
        prev = run((async function* () {
            let result = fn(...args);
            let v;
            if ('next' in result) {
                v = yield* result;
            }
            else {
                v = await result;
            }
            return v;
        })());
        return prev;
    };
}
export function share(fn) {
    let promise = null;
    let clients = 0;
    function onCancel() {
        if (!promise)
            return;
        clients -= 1;
        if (clients > 0)
            return;
        promise.cancel();
        clients = 0;
        promise = null;
    }
    async function* start() {
        if (promise == null) {
            promise = run(fn());
        }
        clients += 1;
        const ctx = yield* context();
        ctx.onCancel(onCancel);
        try {
            return await promise;
        }
        finally {
            clients -= 1;
            promise = null;
            ctx.done();
        }
    }
    return () => run(start());
}
export async function* forever(fn) {
    const ctx = yield* context();
    while (!ctx.isCanceled()) {
        yield* fn();
    }
}
