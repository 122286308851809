import { CancelableGenerator, CancelablePromise, run } from "@repaya/commons/async2"
import { useCallback, useEffect, useRef, MouseEvent as ReactMouseEvent, Dispatch, SetStateAction, useState, useLayoutEffect, RefObject } from "react"
import { shallowEqual } from "./util"

/**
 * Dan Abramov's naive useEvent implementation
 * 
 * @param fn event fn
 * @returns callback fn
 */
export function useEvent<A extends any[], T>(fn: (...args: A) => T): (...args: A) => T {
    const ref = useRef(fn)
    ref.current = fn
    return useCallback((...args: A) => ref.current(...args), [])
}

/**
 * Simple periodic useEffect
 */
export function useInterval(fn: (...args: any[]) => unknown, timeout: number) {
    const event = useEvent(fn)
    useEffect(() => {
        const timer = setInterval(event, timeout)
        return () => clearInterval(timer)
    }, [timeout])
}

type KeyedEffectFn<K extends any[]> = (...keys: K) => ((() => void) | void)

/**
 * Calls the specified effect but only once app-wide for each key
 *
 * similar to how useSWR's keys work
 * 
 * @param unmountTimeout timeout to "debounce" unmount handlers and wait for new subscribers
 * @returns hook function to call with keys and the effect
 */
export function createCommonEffect<K extends any[]>(unmountTimeout: number = 50) {
    let clientCount: Record<string, number> = {}
    let cleanup: Record<string, unknown | null | (() => void)> = {}
    let unmountTimers: Record<string, ReturnType<typeof setTimeout> | null> = {}

    return (keys: K, effect: KeyedEffectFn<K>) => {
        const key = JSON.stringify(keys)
        useEffect(() => {
            if (!(key in clientCount)) {
                clientCount[key] = 0
            }

            const timer = unmountTimers[key]
            if (timer != null) {
                clearTimeout(timer)
                delete unmountTimers[key]
            }

            clientCount[key] += 1
            if (timer == null && clientCount[key] === 1)
                cleanup[key] = effect(...keys)

            return () => {
                clientCount[key] -= 1
                if (clientCount[key] !== 0) return

                if (unmountTimers[key] != null) return
                unmountTimers[key] = setTimeout(() => {
                    delete unmountTimers[key]
                    if (clientCount[key] !== 0) return

                    const fn = cleanup[key]
                    if (fn instanceof Function)
                        fn()
                }, unmountTimeout)
            }
        }, keys)  // fn is based on keys
    }
}

let globalEffectKeyId = 0

export function createGlobalEffect(unmountTimeout: number = 50) {
    const useCommonEffect = createCommonEffect(unmountTimeout)
    const key = `__global_effect_${++globalEffectKeyId}`

    return (effect: () => ((() => void) | void)) => {
        useCommonEffect([key], effect)
    }
}

/**
 * Like useMemo but keyed, usefull for creating repeating callbacks with different arguments
 */
export function useKeyMemo<T, D extends any[]>(get: (key: string | number) => T, deps: D): (key: string | number) => T {
    const state = useRef<{ [key: string | number]: T }>({})

    useEffect(() => {
        state.current = {}
    }, deps)

    return useCallback(index => {
        if (index in state.current) {
            return state.current[index]
        }

        const value = get(index)
        state.current[index] = value
        return value
    }, deps)
}

export function useCallbackStopPropagation<
    E extends MouseEvent, H extends Element,
    F extends (e: ReactMouseEvent<H, E>) => boolean | any,
    D extends any[]
>(f: F, deps: D): (e: ReactMouseEvent<H, E>) => void {
    return useCallback(e => {
        const r = f(e)
        if (r === false || r === undefined) {
            e.stopPropagation()
        }
    }, deps)
}

export function useToggleState(value: boolean, opts?: { timeout?: number }): [boolean, Dispatch<SetStateAction<boolean | void>>] {
    const [state, setState] = useState(value)

    const onToggle = useCallback((arg: SetStateAction<boolean | void>) => {
        return setState(prev => {
            let next = arg
            if (next instanceof Function) {
                next = next(prev)
            }

            if (next === undefined) {
                return !prev
            }

            return next
        })
    }, []) as Dispatch<SetStateAction<boolean | void>>

    useEffect(() => {
        if (opts && opts.timeout && state) {
            const timer = setTimeout(() => setState(false), opts.timeout)
            return () => clearTimeout(timer)
        }
    }, [state, opts])

    return [state, onToggle]
}

export function useThrottledEvent(fn: () => void, timeout: number): () => void {
    const fnRef = useRef<() => void>(fn)
    fnRef.current = fn
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    useEffect(() => () => {
        if (timerRef.current != null) clearTimeout(timerRef.current)
    }, [])

    return useEvent(() => {
        if (timerRef.current == null) {
            timerRef.current = setTimeout(() => {
                timerRef.current = null
                fnRef.current()
            }, timeout)
        }
    })
}

export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T | undefined>(undefined)
    const prev = ref.current
    ref.current = value
    return prev
}

// function checkDarkMode(): boolean | null {
//     if (typeof window === 'undefined') return null
//     if (Array.from(document.getElementsByTagName('html')[0].classList).includes('dark')) return true
//     return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
// }

export function useIsDarkMode(): boolean | null {
    return true
    // const [state, setState] = useState<null | boolean>(null)

    // useEffect(() => {
    //     if (typeof window === 'undefined') return

    //     setState(checkDarkMode())

    //     function handler(event: MediaQueryListEvent) {
    //         const isDarkMode = event.matches ? true : false;
    //         setState(isDarkMode)
    //     }

    //     const match = window.matchMedia('(prefers-color-scheme: dark)')
    //     match.addEventListener('change', handler);

    //     return () => match.removeEventListener('change', handler)
    // }, [])

    // return state
}

export function useIsMounted(cb?: () => void): () => boolean {
    const isMountedRef = useRef(true)

    useEffect(() => {
        isMountedRef.current = true
        return () => {
            if (cb) cb()
            isMountedRef.current = false
        }
    }, [])

    return () => isMountedRef.current
}

export function createGlobalCache<T>(gen: (key: string) => T): (key: string) => T {
    const cache: Record<string, T> = {}

    return key => {
        if (!(key in cache)) cache[key] = gen(key)

        return cache[key]
    }
}

export function useAsync<A extends any[], T>(cb: (...args: A) => CancelableGenerator<T>): [(...args: A) => CancelablePromise<T>, boolean] {
    const [isLoading, setIsLoading] = useState(false)
    let promise = useRef<CancelablePromise<T> | null>(null)

    const wrapped = useEvent((...args: A) => {
        if (promise.current) promise.current.cancel()
        promise.current = run(async function* () {
            setIsLoading(true)
            try {
                const result = yield* cb(...args)
                setIsLoading(false)
                return result
            } catch (error) {
                setIsLoading(false)
                throw error
            }
        }())
        return promise.current
    })

    useIsMounted(() => {
        if (promise.current && promise.current.cancel) promise.current.cancel()
    })

    return [wrapped, isLoading]
}

export function useLayoutEffectOnce(effect: () => (() => void) | void) {
    const isFired = useRef(false)

    useLayoutEffect(() => {
        if (isFired.current) return
        isFired.current = true

        return effect()
    }, [])
}

export function useEffectOnce(effect: () => (() => void) | void, cond: boolean = true) {
    const isFired = useRef(false)

    useEffect(() => {
        if (!cond) return

        if (process.env.NODE_ENV !== 'development') {
            if (isFired.current) return
            isFired.current = true
        }

        return effect()
    }, [cond])
}

export function useTimeout<D extends any[]>(fn: (() => void) | null, timeout: number, deps: D) {
    const event = useEvent(() => {
        if (fn) fn()
    })

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null

        if (timeout == null) return

        timer = setTimeout(event, timeout)
        return () => {
            if (timer != null) clearTimeout(timer)
        }
    }, [...deps, timeout])
}

export function clientSideHook<A extends any[], T>(hook: (...args: A) => T) {
    return hook
    // return (...args: A) => {
    //     const [state, setState] = useState<T | null>(null)

    //     const value = hook(...args)

    //     useEffect(() => {
    //         if (!shallowEqual(state, value)) setState(value)
    //     }, [{}])

    //     return state
    // }
}

export function useIsFirstTimeLoading(isLoading: boolean) {
    const isLoadingRef = useRef(isLoading)

    if (isLoadingRef.current && !isLoading) {
        isLoadingRef.current = false
    }

    return isLoadingRef.current
}

export function useScrollInterpolate(start: number, stop: number, cb: (f: number) => void, node?: RefObject<Element | null>) {
    const event = useEvent(cb)
    useEffect(() => {
        const root = node ? node.current!.parentNode : window
        const handler = () => {
            const s = (root! as Element).scrollTop
            const y = Math.min(1, Math.max(0, (s - start) / (stop - start)))
            event(y)
            return false
        }

        handler()
        root!.addEventListener('scroll', handler)
        return () => {
            root!.removeEventListener('scroll', handler)
        }
    }, [start, stop])
}

export function useOnScroll<A>(cb: (number: number, arg?: A) => void, once?: () => A) {
    const event = useEvent(cb)
    const onceEvent = useEvent(() => {
        if (once) return once()
        return undefined
    })

    useEffect(() => {
        const arg = onceEvent()
        const handler = () => {
            const s = window.scrollY
            event(s, arg)
            return false
        }

        handler()
        window.addEventListener('scroll', handler)
        return () => {
            window.removeEventListener('scroll', handler)
        }
    }, [])
}

interface UseScrollAreaCallbackOptions {
    start: number
    end: number
    enter: (up: boolean) => void
    exit: (up: boolean) => void
}

export function useSrollAreaCallback(opts: UseScrollAreaCallbackOptions) {
    const stateRef = useRef(opts.start <= 0)

    useOnScroll(scroll => {
        const show = scroll >= opts.start && scroll < opts.end
        const prev = stateRef.current

        if (show && !prev) {
            stateRef.current = true
            opts.enter(scroll < (opts.start + opts.end - opts.start / 2))
        } else if (!show && prev) {
            stateRef.current = false
            opts.exit(scroll < opts.start)
        }
    })
}