import { wait } from "./cancellable.js";
export class Signal {
    promise;
    resolver = null;
    constructor() {
        this.promise = new Promise(resolve => {
            this.resolver = resolve;
        });
    }
    async *wait() {
        yield* wait(this.promise);
    }
    notify() {
        const buff = this.resolver;
        this.promise = new Promise(resolve => {
            this.resolver = resolve;
        });
        buff(1);
    }
}
