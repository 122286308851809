import { axios } from "../../async2/index.js";
import cookie from 'cookie';
export class BrowserClient {
    url;
    path;
    id = 0;
    withCredentials;
    constructor(url, withCredentials = false) {
        const urlObj = new URL(url);
        this.url = `${urlObj.protocol}//${urlObj.host}`;
        this.path = urlObj.pathname.slice(1);
        this.withCredentials = withCredentials;
    }
    async *requestWrapped(method, params, authToken) {
        const request = this.prepareRequest(method, params);
        const requestConfig = {
            method: 'POST',
            baseURL: this.url,
            url: this.path,
            data: request,
            withCredentials: this.withCredentials
        };
        if (typeof window !== 'undefined') {
            const cookies = cookie.parse(document.cookie);
            if (cookies['csrf'])
                requestConfig.headers = { 'X-CSRF-Token': cookies['csrf'] };
        }
        if (authToken)
            requestConfig.headers = { Authorization: `Bearer ${authToken}` };
        const response = yield* axios(requestConfig);
        if (response.status !== 200) {
            throw new Error(`RPC returned non 200 status code: ${response.status}`);
        }
        const contentType = response.headers['content-type'];
        if (!contentType?.toLowerCase().startsWith('application/json')) {
            throw Error(`unexpected content type "${contentType}" expected application/json`);
        }
        return response.data;
    }
    async *request(method, params, authToken) {
        const data = yield* this.requestWrapped(method, params, authToken);
        if ('error' in data) {
            throw data.error;
        }
        return data.result;
    }
    async *requestBatch(batch) {
        if (batch.length === 0)
            return [];
        const requests = batch.map(({ method, params }) => this.prepareRequest(method, params));
        const response = yield* axios({
            method: 'POST',
            baseURL: this.url,
            url: this.path,
            data: requests,
            withCredentials: this.withCredentials
        });
        if (response.status !== 200) {
            throw new Error(`RPC returned non 200 status code: ${response.status}`);
        }
        const contentType = response.headers['content-type'];
        if (!contentType?.toLowerCase().startsWith('application/json')) {
            throw Error(`unexpected content type "${contentType}" expected application/json`);
        }
        if (!Array.isArray(response.data)) {
            const data = response.data;
            throw data.error;
        }
        const resultsById = {};
        for (let item of response.data) {
            const data = item;
            if ('error' in data) {
                throw data.error;
            }
            resultsById[data.id] = data.result;
        }
        return requests.map(({ id }) => resultsById[id]);
    }
    prepareRequest(method, params) {
        return {
            jsonrpc: '2.0',
            method,
            params,
            id: `${++this.id}`
        };
    }
}
