import { run } from "@repaya/commons/async2"
import { request } from "api/request"
import produce from "immer"
import { posthog } from "posthog-js"
import create from "zustand"
import { withStoreEffect } from "../common/state"
import { isUnauthorizedError } from "./error"
import { User } from './types'


interface AuthState {
    user: User | null
    isUpdating: boolean
    isLoading: boolean
    setUser(user: User | null): void
    setLoading(loading?: boolean): void
    refresh(): void
}

const useBaseAuthStore = create<AuthState>(set => ({
    user: null,
    isLoading: true,
    isUpdating: true,
    setUser: user => set(produce(recipe => {
        if (process.env.NODE_ENV === 'production') {
            if (user && !recipe.user) {
                const duid = posthog.get_distinct_id()
                const uid = `user_${user.id}`
                posthog.identify(uid, { address: user.address })
                if (!duid.startsWith('user_')) posthog.alias(uid, duid)
            } else if (!user && recipe.user) {
                posthog.reset()
            }
        }
        recipe.user = user
        recipe.isUpdating = false
        recipe.isLoading = false
    })),
    setLoading: (loading: boolean = true) => set(produce(recipe => {
        recipe.isUpdating = loading
        recipe.isLoading = loading
    })),
    refresh: () => set(produce(recipe => {
        recipe.isLoading = true
        recipe.isUpdating = false
    }))
}))

export const useAuthStore = withStoreEffect(useBaseAuthStore, () => {
    if (!useBaseAuthStore.getState().isLoading) return

    async function revalidate() {
        useAuthStore.getState().setLoading()
        try {
            const user = await run(request<User | null>('getUser', []))
            useBaseAuthStore.getState().setUser(user)

            if (!user) return
        } catch (error) {
            useAuthStore.getState().setLoading(false)
            if (isUnauthorizedError(error)) return
            throw error
        }

    }

    const timer = setInterval(() => {
        useBaseAuthStore.getState().refresh()
    }, 20000)

    const unsub = useBaseAuthStore.subscribe(state => {
        if (state.isLoading && !state.isUpdating) revalidate()
    })

    useBaseAuthStore.getState().refresh()

    return () => {
        if (timer !== null) clearTimeout(timer)
        unsub()
    }
})
